import React, { useState } from 'react';

const CookieConsent = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="cookie-consent-overlay">
          <div className="cookie-consent-popup">
            <h2>Cookie Consent</h2>
            <p>We use cookies to ensure you get the best experience on our website.</p>
            <div className="button-container">
              <button onClick={handleConsent} className="reject-button">Reject</button>
              <button onClick={handleConsent} className="accept-button">Accept</button>
            </div>
          </div>
        </div>
      )}
      <style jsx global>{`
        .cookie-consent-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .cookie-consent-popup {
          background: linear-gradient(to bottom, #FF69B4, #C71585, #8B008B);
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
          text-align: center;
          color: #fff;
          max-width: 400px;
          width: 100%;
        }
        .cookie-consent-popup h2, .cookie-consent-popup p {
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
        }
        .cookie-consent-popup h2 {
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: bold;
          color: white;
        }
        .cookie-consent-popup p {
          margin-bottom: 20px;
          font-size: 16px;
        }
        .button-container {
          display: flex;
          justify-content: space-between;
        }
        .reject-button, .accept-button {
          flex: 1;
          padding: 15px 30px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 18px;
          font-weight: bold;
          border: none;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
          transition: transform 0.1s ease-in-out;
        }
        .reject-button {
          background-color: #dc3545;
          color: #fff;
          margin-right: 10px;
        }
        .accept-button {
          background-color: #28a745;
          color: #fff;
        }
        .reject-button:hover, .accept-button:hover {
          transform: translateY(-2px);
        }

        @media (max-width: 500px) {
          .cookie-consent-overlay {
            align-items: flex-start;
          }
          .cookie-consent-popup {
            max-width: 80%;
            padding: 20px;
            top: 25%;
            position: relative;
          }
          .cookie-consent-popup h2 {
            font-size: 18px;
          }
          .cookie-consent-popup p {
            font-size: 14px;
          }
          .reject-button, .accept-button {
            font-size: 16px;
            padding: 12px 25px;
          }
        }
      `}</style>
    </>
  );
};

export default CookieConsent;
