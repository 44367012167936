import React from 'react';
import Image from 'next/image';

interface ServiceCardProps {
  id: string;
  src: string;
  title: string;
  description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ id, src, title, description }) => {
  return (
    <div key={id} className="service-card">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="service-image">
          <Image src={src} alt={title} layout="fill" objectFit="cover" />
        </div>
        <div>
          <div className="service-title">{title}</div>
          <div className="service-description">{description}</div>
        </div>
      </div>

      <style jsx>{`
        .service-card {
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
          border-radius: 10px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19),
                      0 0 20px rgba(255, 255, 255, 0.3),
                      0 0 30px rgba(0, 0, 0, 0.6);
          padding: 20px;
          text-align: center;
          color: white;
          transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
          cursor: pointer;
        }

        .service-card:hover {
          background: linear-gradient(to bottom, rgba(50, 50, 50, 0.8), rgba(30, 30, 30, 0.9));
          backdrop-filter: blur(5px);
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19),
                      0 0 25px rgba(208, 38, 112, 0.5),
                      0 0 40px rgba(0, 0, 0, 0.8);
        }

        .service-image {
          position: relative;
          width: 100px;
          height: 100px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .service-title {
          font-weight: bold;
          font-size: 1.6rem;
          margin: 10px 0;
        }

        .service-description {
          font-size: 1.2rem;
          margin: 10px 0;
        }

        @media (max-width: 800px) {
          .service-card {
            padding: 18px;
          }

          .service-image {
            width: 90px;
            height: 90px;
          }

          .service-title {
            font-size: 1.5rem;
          }

          .service-description {
            font-size: 1.1rem;
          }
        }

        @media (max-width: 600px) {
          .service-card {
            padding: 15px;
          }

          .service-image {
            width: 80px;
            height: 80px;
          }

          .service-title {
            font-size: 1.4rem;
          }

          .service-description {
            font-size: 1rem;
          }
        }

        @media (max-width: 500px) {
          .service-card {
            padding: 12px;
          }

          .service-image {
            width: 70px;
            height: 70px;
          }

          .service-title {
            font-size: 1.3rem;
          }

          .service-description {
            font-size: 0.95rem;
          }
        }

        @media (max-width: 400px) {
          .service-card {
            padding: 10px;
          }

          .service-image {
            width: 60px;
            height: 60px;
          }

          .service-title {
            font-size: 1.2rem;
          }

          .service-description {
            font-size: 0.9rem;
          }
        }
      `}</style>
    </div>
  );
};

export default ServiceCard;
