const cardData = [
  {
    id: 'buy-crypto',
    src: '/assets/buy.png',
    title: 'Buy Crypto',
    description: 'Purchase crypto quickly and easily on our popular and industry-leading platform.',
  },
  {
    id: 'spot-trading',
    src: '/assets/bitcoin.png',
    title: 'Spot Trading',
    description: 'Trade crypto with our comprehensive set of powerful tools to maximize your profits.',
  },
  {
    id: 'expanding-markets',
    src: '/assets/socks.png',
    title: 'Expanding Markets',
    description: 'We are continuously adding new stocks, commodities, and other opportunities to trade across various markets.',
  },
  {
    id: 'company-listing',
    src: '/assets/laptop.png',
    title: 'Company Listing',
    description: 'Easily list your company on our platform and reach global markets.',
  }
];

export default cardData;
