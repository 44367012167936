import React from 'react';
import Image from 'next/image';

interface TokenInfoProps {
  isTokenInfoVisible: boolean;
}

const TokenInfoSection: React.FC<TokenInfoProps> = ({ isTokenInfoVisible }) => {
  return (
    <>
      <style jsx global>{`
        .Tokeninfo-section {
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: white;
          padding: 50px;
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
          transition-delay: 0s; /* No delay when hidden */
        }

        .Tokeninfo-section.visible {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.2s; /* 0.3s delay before becoming visible */
        }

        .Tokeninfo-text {
          max-width: 600px;
        }

        .Tokeninfo-heading {
          font-size: 2.5em;
          color: white;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          padding: 10px;
          border-radius: 5px;
          font-weight: bold;
          margin-bottom: 1rem;
          text-align: center;
          background: linear-gradient(to bottom, #D02670, #a01e5a);
          box-shadow: 0 0 15px rgba(208, 38, 112, 0.7), 0 0 20px rgba(208, 38, 112, 0.5); 
        }

        .Tokeninfo-paragraph {
          font-size: 1.5em;
          margin-bottom: 1rem;
        }

        .Tokeninfo-link {
          color: white;
          text-decoration: underline;
          cursor: pointer;
        }

        .Tokeninfo-image {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 350px;
          height: 350px;
          overflow: hidden;
        }

        @media (max-width: 1050px) {
          .Tokeninfo-image {
            display: none;
          }
        }

        @media (max-width: 800px) {
          .Tokeninfo-heading {
            font-size: 2em;
          }

          .Tokeninfo-paragraph {
            font-size: 1.2em;
          }
        }

        @media (max-width: 600px) {
          .Tokeninfo-heading {
            font-size: 1.5em;
          }

          .Tokeninfo-paragraph {
            font-size: 1em;
          }
        }
      `}</style>

      <div className={`Tokeninfo-section ${isTokenInfoVisible ? 'visible' : ''}`}>
        <div className="Tokeninfo-image">
          <Image src="/assets/PinxLogo.png" alt="Growth" layout="fill" objectFit="contain" />
        </div>
        <div className="Tokeninfo-text">
          <h1 className="Tokeninfo-heading">A Token Designed To Empower The Pinx Community</h1>
          <p className="Tokeninfo-paragraph">
            Transform your trading with Pinx Token! Imagine having a key that unlocks special deals, earns you money while you hold onto it, and gives you early access to exciting new investments. With Pinx Token, you&apos;re not just trading; you&apos;re stepping into a world filled with amazing possibilities. Start your adventure with us and see your finances in a whole new light.
          </p>
        </div>
      </div>
    </>
  );
};

export default TokenInfoSection;

