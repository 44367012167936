import React from 'react';
import ServiceCard from './ServiceCard';
import cardData from './cardData'; 

const Services: React.FC = () => {
  return (
    <div className="services-container">
      <h2 className="services-heading">Explore Pinx Products & Services</h2>
      <div className="services-grid">
        {cardData.map((card) => (
          <ServiceCard 
            key={card.id}
            id={card.id}
            src={card.src}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>

      <style jsx global>{`
        .services-container {
          padding: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .services-heading {
          color: white;
          text-align: center;
          margin-bottom: 30px;
          font-size: 2.3rem;
          text-shadow: 3px 3px 6px rgba(0,0,0,0.5);
        }

        .services-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          width: 100%;
          max-width: 1200px;
        }

        @media (max-width: 600px) {
          .services-grid {
            grid-template-columns: 1fr;
          }

          .services-heading {
            font-size: 1.8rem;
          }
        }

        @media (max-width: 400px) {
          .services-heading {
            font-size: 1.5rem;
          }

          .services-container {
            padding: 30px;
          }
        }
      `}</style>
    </div>
  );
};

export default Services;
